import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../../assets/styles/layout"

import { FooterContainer } from "../../components/global/common/containers"

import CallToAction from "../../components/global/CallToAction"
import Footer from "../../components/global/Footer"
import Partners from "../../components/global/Partners"

import OfferHeader from "../../components/global/header/OfferHeader"
import WhyUs from "../../components/web-support/WhyUsVideo"
import WaysOfUsing from "../../components/web-support/WaysOfUsing"
import Packages from "../../components/web-support/Packages"
import IndividualApproach from "../../components/web-support/IndividualApproach"
import HowDoesItWork from "../../components/web-support/HowDoesItWork"
import Reasons from "../../components/web-support/Reasons"
import CaseStudies from "../../components/global/OfferCaseStudies"
import Faq from "../../components/global/Faq"

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfCallToAction {
        ctaPersonPosition
        ctaPersonName
        ctaForm
        ctaImage {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        ctaContactOptions {
          eMail
          phone
        }
        ctaButtons {
          ctaButton1 {
            url
            title
            target
          }
          ctaButton2 {
            target
            url
            title
          }
        }
        ctaIntro
        ctaTitle
      }
      acfWebSupport {
        individualApproachExists
        individualApproachGallery {
          id
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            publicURL
          }
        }
        individualApproachTitle
        individualApproachDescription
        individualApproachButton {
          url
          title
          target
        }
        faqExists
        faqList {
          description
          fieldGroupName
          header
        }
        faqTitle
        fieldGroupName
        grayBoxHeader
        image {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        whyUsTitle
        whyUsTextBelowTitle
        whyUsTextBelowSubtitle
        whyUsSubtitle
        waysOfUsingExists
        title
        waysTitle
        testimonialAuthorPosition
        testimonialAuthorName
        testimonialAuthorLogo {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            publicURL
          }
        }
        howDoesItWorkExists
        howDoesItWorkTitle
        howDoesItWorkList {
          header
          description
        }
        reasonsExists
        packagesTitle
        packagesFooter
        packagesExists
        whyUsSectionExists
        packages {
          price
          header
          fieldGroupName
          features {
            secondLine
            firstLine
            fieldGroupName
          }
          button {
            url
            title
            target
          }
        }
        offerWhyUsMovie
        offerCaseStudiesTitle
        offerCaseStudiesList {
          ... on WpCaseStudy {
            id
            featuredImage {
              node {
                altText
                atttachement_pl {
                  altTextPl
                }
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
            acfCaseStudy {
              intro
              whiteLogo {
                altText
                atttachement_pl {
                  altTextPl
                }
                localFile {
                  publicURL
                }
              }
            }
            title
            uri
          }
        }
        offerCaseStudiesExists
        waysList {
          header
          fieldGroupName
          description
        }
        intro
        indentedSectionTitle
        reasonsList {
          header
          description
          fieldGroupName
        }
        indentedSectionImage {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        indentedSectionButton {
          target
          title
          url
        }
      }
    }
  }
`

const WebSupport = ({ pageContext, data: { wpPage } }) => {
  const { seo } = wpPage
  const webSupportData = wpPage.acfWebSupport

  const is = {
    whyUs: webSupportData.whyUsSectionExists,
    waysOfUsing: webSupportData.waysOfUsingExists,
    packages: webSupportData.packagesExists,
    individualApproach: webSupportData.individualApproachExists,
    howDoesItWork: webSupportData.howDoesItWorkExists,
    reasons: webSupportData.reasonsExists,
    caseStudies: webSupportData.offerCaseStudiesExists,
    faq: webSupportData.faqExists,
  }

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={wpPage} />
      )}
      <OfferHeader data={webSupportData} pageContext={pageContext} />
      {is.whyUs && <WhyUs data={webSupportData} />}
      {is.waysOfUsing && <WaysOfUsing data={webSupportData} />}
      {is.packages && <Packages data={webSupportData} />}
      {is.individualApproach && <IndividualApproach data={webSupportData} />}
      {is.howDoesItWork && <HowDoesItWork data={webSupportData} />}
      {is.reasons && <Reasons data={webSupportData} />}
      {is.caseStudies && <CaseStudies data={webSupportData} />}
      {is.faq && <Faq data={webSupportData} />}
      <Partners />
      <FooterContainer>
        <CallToAction data={wpPage.acfCallToAction} />
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default WebSupport
